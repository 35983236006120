.footer{
    &-container{
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        @include r(767){
            padding-top: 30px;
            padding-bottom: 20px;
        }
    }
    &__top,
    &__bottom{
        justify-content: center;
        @include rmin(768){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__top{
        margin-bottom: 25px;
        @include r(767){
            margin-bottom: 15px;
        }
    }
    .logo-link{
        color: $color-primary;
        @include rmin(768){
            flex: 0 0 230px;
            max-width: 230px;
        }

        svg{
            max-height: 25px;
        }
        @include r(767){
            display: none;
        }
    }
    .socials{
        flex: 0 0 auto;
        @include r(767){
            text-align: center;
        }
        a{
            text-decoration: none;
            color: $color-primary;
            &:not(:first-child){
                margin-left: 20px;
                @include r(767){
                    margin-left: 15px;
                }
            }
            svg{
                width: 18px;
                height: 18px;
            }
        }
    }
    .copy{
        color: rgba($color-primary, .5);
        font-size: 14px;
        flex: 0 0 auto;
        @include rIn(768,1023){
            max-width: 280px;
        }
        @include r(767){
            margin-bottom: 15px;
            color: rgba($color-dark, .5);
            text-align: center;
        }
    }
    &__menu{
        @extend %reset-list;
        display: flex;
        align-items: center;
        @include r(767){
            flex-wrap: wrap;
            justify-content: center;
        }
        li:not(:last-child){
            margin-right: 25px;
        }

        a{
            color: $color-primary;
            font-size: 14px;
            text-decoration: none;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                bottom: -5px;
                left: 0;
                background-color: transparent;
                transform: scaleX(0);
                transform-origin: 0 50%;
                transition: transform $transition-base;
            }
            &:hover:not(.is-current){
                &::after{
                    transform: scaleX(1);
                    background-color: currentColor;
                    opacity: .4;
                }
            }
            &.is-current{
                &::after{
                    transform: scaleX(1);
                    background-color: currentColor;
                }
            }
        }
    }
}