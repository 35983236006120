// Font
$main-font: 'Poppins', sans-serif;

// Size
$main-font-size: 15px;
$main-font-weight: 400;
$main-line-height: 24/14;

// Breakpoints
$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1200px
);

// Colors
$color-primary: #799BAD;
$color-white: #FFFFFF;
$color-dark: #404042;
$color-text: rgba(#404042,.8);
$color-gray: rgba(#404042,.3);

// Z-indexes
// $z-indexes: (
//         "modal",
//         "latest-activities",
//         "header",
//         "wrapper",
//         "footer"
// );


// Transitions
$transition-base: 0.4s cubic-bezier(.23, 1, .32, 1);

//transition last activities
$transition-la: .6s cubic-bezier(0.08, 1.00, 0.21, 1.00);