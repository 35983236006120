.slick {
    &-dots {
        display: flex;
        @extend %reset-list;
        button {
            display: block;
            overflow: hidden;
            text-indent: -999px;
            width: 2px;
            height: 100%;
            background-color: #fff;
            transform-origin: 50% 100%;
            transition: transform .4s cubic-bezier(.23,1,.32,1);
            @extend %reset-btn;
        }
        li {
            @extend %reset-list;
            &:not(:first-child) {
                margin-left: 20px;
            }
        }
    }
    &-active {
        button {
            transform: scaleY(2)
        }
    }
}