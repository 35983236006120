.animate-line,
.animate-slider-line {
    opacity: 0;
    transform: translateY(50px);
    transition: transform 0.5s ease-out, opacity .5s ease-out;

    &_1 {
        transition-delay: 0.1s;
    }

    &_2 {
        transition-delay: 0.2s;
    }

    &_3 {
        transition-delay: 0.3s;
    }

    &_4 {
        transition-delay: 0.4s;
    }

    &_5 {
        transition-delay: 0.5s;
    }
}
.animate-line_from-opacity{
    opacity: 0;
    transition: opacity 0.5s ease-out;
    &_1 {
        transition-delay: 0.2s;
    }

    &_2 {
        transition-delay: 0.4s;
    }

    &_3 {
        transition-delay: 0.6s;
    }

    &_4 {
        transition-delay: 0.8s;
    }

    &_5 {
        transition-delay: 1s;
    }
}
.js-slider-section.is-active .animate-slider-line,
.js-section.is-active .animate-line {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.js-section.is-active .animate-line_from-opacity{
    opacity: 1;
}

.animate-image {
    visibility: hidden;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    &.is-visible {
        visibility: visible;
    }
}


// .animate-letter {
//     display: block;
//     opacity: 0;
//     -webkit-transform: translateY(15px);
//     transform: translateY(15px);
//     -webkit-transition: 0.2s ease-out;
//     transition: 0.2s ease-out;

//     &_1 {
//         -webkit-transition-delay: 0.1s;
//         transition-delay: 0.1s;
//     }

//     &_2 {
//         -webkit-transition-delay: 0.2s;
//         transition-delay: 0.2s;
//     }

//     &_3 {
//         -webkit-transition-delay: 0.3s;
//         transition-delay: 0.3s;
//     }

//     &_4 {
//         -webkit-transition-delay: 0.4s;
//         transition-delay: 0.4s;
//     }

//     &_5 {
//         -webkit-transition-delay: 0.5s;
//         transition-delay: 0.5s;
//     }

//     &_6 {
//         -webkit-transition-delay: 0.6s;
//         transition-delay: 0.6s;
//     }

//     &_7 {
//         -webkit-transition-delay: 0.7s;
//         transition-delay: 0.7s;
//     }

//     &_8 {
//         -webkit-transition-delay: 0.8s;
//         transition-delay: 0.8s;
//     }

//     &_9 {
//         -webkit-transition-delay: 0.9s;
//         transition-delay: 0.9s;
//     }

//     &_10 {
//         -webkit-transition-delay: 1s;
//         transition-delay: 1s;
//     }
// }

// .js-section.is-active .animate-letter {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
// }

// .animate-letter__wrapper {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
// }



//// intro