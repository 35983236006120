.location {
    margin-top: 90px;
    @include r(768) {
        margin-top: 24px;
    }
    &__map {
        // padding-top: 78px;
        display: block;
        width: 100%;
        height: 792px;
        overflow: hidden;
        @include r(768) {
            padding-top: 24px;
            height: 476px;
        }
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    &__big-img{
        margin-bottom: -40px;
    }
    .about__grid {
        padding-bottom: 100px;
        @include r(768) {
            padding-bottom: 45px;
        }
    }
    .about__right {
        &::after {
            display: none;
        }
    }
}

.text {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            position: relative;
            padding-left: 18px;
            &::before {
                margin-right: 12px;
                content: '';
                position: absolute;
                display: inline-block;
                top: 10px;
                left: 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $color-primary;
                vertical-align: middle;
            }
        }
    }
}