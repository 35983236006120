
.preloader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999999;
    &__block {
        position: relative;
        display: flex;
        width: 190px;
        height: 190px;
        z-index: 2;
    }
    &__logo {
        position: absolute;
        width: 74px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        opacity: 0;
        animation: opacity 1s linear forwards;
        animation-delay: 1s;
    }
    &__lines {
        @extend %overlay; 
        width: 80%;
        height: 100%;
        margin: auto;
    }
    &__line {
        position: absolute;
        display: inline-block;
        z-index: -1;
        &.line-1 {
            width: 545px;
            height: 545px;
            left: 0;
            bottom: -15px;
            @include r(1240) {
                width: 250px;
                height: 250px;
            }
            line {
                stroke-dasharray: 1538;
                stroke-dashoffset: 1538;
                animation: draw1 2s linear forwards;
                animation-delay: 2s;
            }
        }
        &.line-2 {
            width: 1084px;
            height: 1084px;
            bottom: 20px;
            right: -200px;
            @include r(1240) {
                width: 500px;
                height: 500px;
                top: -80px;
            }
            line {
                stroke-dasharray: 3063;
                stroke-dashoffset: 3063;
                animation: draw1 4s linear forwards;
            }
        }
    }
    svg {
        fill: none;
    }
}

#preloader {
    display: block;
    opacity: 0;
    animation: spinner 1s linear infinite, opacity 1s linear forwards;
    animation-delay: 1.5s;
}

@keyframes spinner {
	to {
        transform: rotateZ(-360deg);
	}
}

@keyframes opacity {
    to {
        opacity: 1;
    }
}

@keyframes draw1{
    to {stroke-dashoffset: 0;}
}