.about-page {
    .inner-intro__text {
        display: flex;
        @include r(768){
            max-width: unset;
            text-align: center;
            flex-direction: column;
            align-items: center;
        }
        @include rmin(992){
            align-items: flex-start;
        }
        img {
            margin-right: 24px;
            display: block;
            width: 63px;
            height: 63px;
            border-radius: 50%;
            @include r(768) {
                margin: 0 auto 3px;
                // position: absolute;
                // left: 0;
                // right: 0;
                // margin: 0 auto;
                // bottom: -28px;
                // z-index: 2;
                // border: 10px solid #fff;
            }
        }

        p {
            margin-top: 0;
        }
    }
    .inner-intro .full-img{
        min-height: 38vw;
    }
}

.about-us {
    padding-top: 30px;

    @include rmin(992) {
        padding-top: 64px;
    }
    &__header {
        @include rmin(768) {
            padding: 0;
        }

    }
    &__gallery {
        padding: 30px 0;

        @include rmin(992) {
            padding: 65px 0;
        }
    }
    &__btn {
        display: none;
        padding-bottom: 40px;
        @include rmin(768) {
            display: block;
        }
    }
    .slick-dots {
        justify-content: center;
        margin-top: 40px;
        button {
            background-color: $color-primary;
            opacity: 0.2;
        }
        .slick-active {
            button {
                opacity: 1;
            }
        }
    }
}

.gallery {
    &__items {
        display: block;

        @include rmin(768){
            display: grid;
            grid-auto-flow: dense;
            grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
            grid-auto-rows: 150px;
            grid-gap: 20px;
        }
        @include rmin(992){
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            grid-auto-rows: 200px;
            grid-gap: 20px;
        }
        @include rmin(1200){
            grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
            grid-auto-rows: 270px;
            grid-gap: 30px;
        }
    }
    &__item {
        padding: 0 10px;
        @include rmin(768){
            padding: 0;
        }
        &:first-child,
        &:nth-child(14n+12),
        &:nth-child(14n+15){
            grid-column: span 2;
            grid-row: span 2;
        }

    }
    &__link {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: $transition-base;
        &::after {
            content: "";
            margin: auto;
            @extend %overlay;
            width: 62px;
            height: 62px;
            background-image: url('../img/svg/plus.svg');
            background-repeat: no-repeat;
            background-position: center;
            transform: scale(0);
            transition: $transition-base;
            @include r(768) {
                width: 40px;
                height: 40px;
                transform: scale(1);
            }
        }
        &::before {
            content: "";
            @extend %overlay;
            opacity: 0;
            background: linear-gradient(180deg, rgba(41, 48, 76, 0) 0%, #29304C 100%);
            transition: $transition-base;
        }
        &:hover {
            &::after {
                transform: scale(1);
                transition: $transition-base;
            }
            &::before {
                opacity: 1;
                transition: $transition-base;
            }
            .gallery__title {
                opacity: 1;
                bottom: 20px;
                transition: $transition-base;
            }
        }
    }

    img {
        @include r(992) {
            width: 100%;
        }
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__title {
        position: absolute;
        opacity: 0;
        left: 30px;
        bottom: -50px;
        @include css-lock(18,22,768,1200);
        line-height: 28px;
        font-weight: 600;
        color: #fff;
        transition: $transition-base;
        @include r(768) {
            opacity: 1;
            bottom: 20px;
        }
    }

    // &__big {
    //     grid-column: span 2;
    //     grid-row: span 2;
    // }

    // &__vertical {
    //     grid-column: span 2;
    // }



    .slick-current {
        .gallery__link:before {
            opacity: 1;
        }
    }
}

.fancybox-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    background-color: #fff;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fancybox-infobar {
    span[data-fancybox-index] {
        color: $color-dark;
    }
}

.fancybox-footer
.fancybox-button svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 18px;
    transform: translate(-50%, -50%);
    path {
        fill: none;
        stroke-width: 2px;
    }
}