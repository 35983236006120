.menu-trigger{
    margin-left: auto;
    width: 18px;
    height: 15px;
    display: flex;
    align-items: center;
    transform: scale(1);
    position: relative;
    @include rIn(768,1023){
        transform: scale(1.2);
        transform-origin: 100% 50%;
    }
    @include rIn(1024,1199){
        transform: scale(1.4);
        transform-origin: 100% 50%;
    }
    @include rmin(1200){
        display: none;
    }
    span{
        display: block;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right, transparent 0%, transparent 6px, $color-white 6px, $color-white 100%);
        background-position-x: 0;
        position: relative;
        transition: all $transition-base;
        &::before,
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            background-color: $color-white;
            transition: inherit;
        }
        &::before{
            top: -6px;
            transform-origin: 100% 0%;
        }
        &::after{
            bottom: -6px;
            transform-origin: 100% 100%;
        }
        .menu-open &{
            opacity: 0;
            background-image: linear-gradient(to right, transparent 0%, transparent 100%) !important;
            background-position-x: 100%;
            &::before,
            &::after{
                background-color: darken($color-white, 5%);

            }
            &::before{
                transform: rotate(-45deg);
            }
            &::after{
                transform: rotate(45deg);
            }
        }
    }
}

.dark-header .layout__header,
.layout__header.is-fixed{
    .menu-trigger{
        span{
            background-image: linear-gradient(to right, transparent 0%, transparent 6px, $color-primary 6px, $color-primary 100%);
            &::before,
            &::after{
                background-color: $color-primary;
            }
        }
    }
}

