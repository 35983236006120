.advantages{
    &__section{
        background: linear-gradient(180deg, rgba(245, 248, 252,.6) 0%, rgba(245, 248, 252, 0) 100%);
        padding: 50px 0 75px;
        @include rIn(768,1023){
            padding: 40px 0 50px;
        }
        &_bg{
            background-size: 42% auto;
            background-position: 0 20%;
            background-repeat: no-repeat;
            &-white{
                background-color: $color-white;
            }
        }
        .title-text{
            .text{
                max-width: 700px;
                @include r(767){
                    max-width: 100%;
                }
            }
            @include r(767){
                flex: 1 1 100%;
                max-width: 100%;
                text-align: center;
                br{
                    display: none;
                }
                .uptitle{
                    margin-top: 0;
                }
            }
        }
    }
    &__header{
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include r(767){
            position: relative;
        }
    }
    &__num{
        width: 100px;
        height: 100px;
        overflow: visible;
        position: relative;
        @include r(767){
            position: absolute;
            right: -20px;
            top: -90px;
            span{
                text-shadow: 0px 17px 40px rgba(0, 0, 0, 0.1);
            }
        }
        span{
            @include abs-center();
            font-weight: 500;
            @include css-lock(115,265,450,1200);
            line-height: 1.13;
            text-align: right;
            color: $color-white;
            text-shadow: 0px 70px 70px rgba(0, 0, 0, 0.1);
        }
    }
    &__boxes{
        display: flex;
        flex-flow: row wrap;
        .box{
            width: 100%;
        }
        &_two-col{
            @include rmin(1024){
                justify-content: space-between;
                .box{
                    flex: 1 1 calc(50% - 15px);
                    max-width: calc(50% - 15px);
                    flex-basis: calc(50% - 15px);
                }
            }
        }
    }

}

.box{
    &_icon-text{
        padding: 30px 20px 20px 30px;
        margin-bottom: 30px;
        display: flex;
        background-color: $color-white;
        box-shadow: 0px 48px 64px rgba(0, 0, 0, 0.05);
        @include r(767){
            padding: 20px 15px 25px;
            text-align: center;
            flex-wrap: wrap;
            box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.05);
            .icon{
                margin: 0 auto 20px;
            }
        }
        .icon{
            width: 65px;
            height: 65px;

        }
        .text{
            flex: 1;
            @include rmin(768){
                padding-left: 30px;

            }
            @include r(767){
                flex: 1 1 100%;
                max-width: 100%;
            }
        }
    }
}