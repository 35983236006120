.call-to-action{
    position: relative;
    @include css-lock(420,550,768,1200,min-height);
    @include flex-center();
    &__bg{
        @extend %overlay;
        @extend %bg-cover;
        z-index: -1;
    }
    &__center{
        color: $color-white;
        text-align: center;
        @include rIn(1200,1920){
            margin: 0 auto;
            max-width: 60%;
        }
        @include rmin(1921){
            width: 60%;
            max-width: 1170px;
        }
        @include r(767){
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .uptitle{
        margin-top: 0;
        color: rgba($color-white, .8);
    }
    .text{
        margin-bottom: 40px;
    }
}