.about{
    &__section{
        @include css-lock(35,130,450,1200,padding-top);
        @include css-lock(90,180,450,1200,padding-bottom);
    }
    &__grid{
        display: flex;
        flex-flow: row wrap;
        @include rmin(1921){
            margin: 0 auto;
            max-width: 1200px;
        }
    }
    &__left{
        flex: 0 0 auto;
        position: relative;
        @include rmin(768){
            @include css-lock(60,110,1024,1400, padding-right);
        }
        @include rIn(1024,1199){
            max-width: 50%;
        }
        @include r(1023){
            margin: 40px auto 0px;
            order: 2;
            max-width: 100%;
        }


    }
    &__big-img{
        display: flex;
        width: 100%;
        background: $color-white;
        box-shadow: 0px 70px 70px -70px rgba(0, 0, 0, 0.15);
        img{
            width: 100%;
            height: auto;
        }
    }
    &__small-img{
        position: absolute;
        width: 30%;
        max-width: 250px;
        padding: 20px;
        display: inline-block;
        right: 0;
        bottom: 0;
        background-color: $color-white;
        margin-bottom: -15%;
        z-index: 1;
        @include r(1023){
            padding: 11px;
            width: 150px;
            margin-bottom: -40px;
        }
    }
    &__short-logo{
        position: absolute;
        bottom: -165px;
        left: 46px;
        z-index: 1;
        @include r(1023){
            max-width: 75px;
            bottom: auto;
            top: 20px;
            left: 20px;
        }
    }
    &__right{
        flex: 1;
        position: relative;
        @include r(1023){
            flex: 1 1 100%;
            .uptitle:first-child{
                margin-top: 0;
            }
            &::after{
                content: none;
            }
        }
        @include rIn(1024,1199){
            &::after{
                bottom: -85px;
            }
        }
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            transform: translateY(50%);
            right: 0;
            width: 150px;
            height: 150px;
            background-color: $color-white;
            box-shadow: 0px 90px 90px rgba(0, 0, 0, 0.04);
        }
        .text{
            @include rmin(1024){
                @include css-lock(50,85,1024,1400, padding-left);
            }
            @include r(1023){
                padding-left: 0;
            }
        }
    }
    &__link{
        padding-bottom: 3px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-primary;
        position: relative;
        display: inline-flex;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            transition: transform .23s;

        }
        &:hover{
            &::after{
                transform: scaleX(0);
            }
        }
    }
}

.uptitle{
    margin-top: 45px;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-size: 14px;
    line-height: 21px;
    color: rgba($color-dark, .5);
    @include r(767){
        margin-bottom: 20px;
    }
}

.about-page .inner-intro__text .avatar-wrapper{
    margin: 0 24px 0 0;
    flex: 0 0 auto;
    text-align: center;
    @include r(768){
        margin: 0 auto 14px;
        font-size: 14px;
    }

    img{
        @include rmin(1024){
            width: 158px;
            height: 158px;
        }
        @include rmin(1024){
            margin-right: 0;
        }
    }
}