.page404{
    &__bg,
    &__bg img{
        @extend %overlay;
        z-index: -1;
    }
    &__bg img{
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        max-width: unset;
        min-width: 100%;
        object-fit: cover;
    }
    .layout__main{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        .footer{
            flex: 0 0 auto;
        }
    }
    .inner-intro{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > .container{
            padding-bottom: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                margin-top: 0;
                margin-bottom: 2rem;
            }
        }
    }
}