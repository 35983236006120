%button{
    padding: 18px 30px;
    @extend %reset-btn;
    display: inline-flex;
    flex: 0 0 auto;
    max-width: 100%;
    font-size: 14px;
    font-family: $main-font;
    font-weight: 600;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    @include css-lock(48,58,768,1200, min-height);
    white-space: nowrap;
    transition: all $transition-base;
    text-transform: uppercase;
    border: 2px solid transparent;
    line-height: 1.2;
    @include r(767){
        padding: 13px 20px;
    }
    .btn-flag{
        margin-top: -3px;
        margin-left: 12px;
        width: 45px;
        height: 29px;
    }
    .chevron-right{
        margin-left: 12px;
        width: 6px;
        height: 12px;
    }
}
.btn {
    @extend %button;
    &_primary {
        color: $color-white;
        background-color: $color-primary;
        border-color: $color-primary;
        text-transform: uppercase;
        box-shadow: 0px 16px 32px rgba(56, 76, 95, 0.2);

        @include hover {
            color: $color-white;
            background-color: darken($color-primary, 10%);
            border-color: darken($color-primary, 10%);
        }
    }
    &_arrow{
        padding-right: 75px !important;
        overflow: hidden;
        border: 0;
        @include css-lock(50,60,768,1200, min-height);
        &.btn_sm{
            padding-right: 60px !important;
        }
        .arrow{
            position: absolute;
            width: 46px;
            height: calc(100% + 2px);
            right: -1px;
            top: -1px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.15);
                z-index: 0;
                transform-origin: 100% 50%;
                transition: all $transition-la;

            }
            svg{
                width: 18px;
                height: 5px;
                color: currentColor;
            }
        }
        span{
            position: relative;
            z-index: 2;
        }
        &:hover{
            .arrow{
                &::after{
                    transform: scaleX(5);
                }
            }
        }

    }
    &_outline{
        background-color: transparent;
        color: $color-white;
        border-color: rgba($color-white, .3);
        &::before,
        &::after{
            content: '';
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            left: -1px;
            top: -1px;
            border: 1px solid $color-white;
            transform: scaleX(0) scaleY(0);
            transition: inherit;
        }
        &::before{
            transform-origin: 0 0;
            border-bottom-color: transparent;
            border-right-color: transparent;
        }
        &::after{
            transform-origin: 100% 100%;
            border-top-color: transparent;
            border-left-color: transparent;
        }
        &:hover{
            &::before,
            &::after{
                transform: scale(1);
            }
        }
    }


    &_sm{
        padding: 11px 18px;
        line-height: 1;
        font-size: 14px;
        @include css-lock(42,48,768,1200, min-height);
    }
}