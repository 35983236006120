
.layout__header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    .dark-header &,
    &.is-fixed{
        position: fixed;
        .header{
            .logo-link{
                color: $color-primary;
            }
            .btn_outline-header{
                @include rmin(1200){
                    color: $color-primary;
                    border-color: $color-primary;
                }
            }
        }

    }
    &.is-fixed{
        .header{
            background-color: $color-white;
            padding-top: 0;
            &-container {
                min-height: 69px;
            }
        }
    }
    &:not(.is-fixed){
        .main-menu__close{
            @include rmin(1024){
                // top: 33px;
                right: 12px;
            }
        }
    }
    .dark-header.menu-open &,
    .menu-open &.is-fixed{
        .header{
            .logo-link{
                color: $color-white;
            }
        }
    }

}
.header{
    // @include css-lock(15,65,1200,1920, padding-top);
    background-color: transparent;
    // box-shadow: 0px 25px 25px rgba(32, 2, 75, 0.02);
    transition: all .23s ease;
    @include rmin(1200){
        padding-top: 30px;
    }

    &-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        min-height: 69px;
        &::after{
            content: none !important;
        }
        &.container_wide{
            padding-left: 15px;
            padding-right: 15px;
            @include rIn(1023,1199){
                padding-left: 20px;
                padding-right: 20px;
            }
            @include rmin(1200){
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
    .logo-link{
        color: $color-white;
        flex: 1 1 230px;
        max-width: 230px;
        display: flex;
        position: relative;
        z-index: 11;
        svg{
            max-height: 25px;
        }
        @include r(767){
            flex: 1 1 150px;
            max-width: 150px;
        }
    }

}
body{
    @include r(1199){
        .main-menu__wrapper{
            transition: none;
            visibility: hidden;
            pointer-events: none;
        }
        &.menu-open .main-menu__wrapper{
            visibility: visible;
            pointer-events: auto;
        }
    }
}

.main-menu{
    position: relative;
    @include rmin(1200){
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
    }
    &::before,
    &::after{
        content: '';
        position: fixed;
        top: 0;
    }

    @include rmin(1200){
        margin-right: 6vw;
    }

    &__wrapper{
        display: flex;
        @include r(1199){
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            flex-direction: column;
            background-color: $color-white;
        }
        @include rmin(1200){
            flex: 1;
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: $color-primary;

        @include rmin(1200){
            display: none;
        }
    }
    // &__header{
    //     @include r(1199){
    //         padding: 3px 46px;
    //         z-index: 1;
    //         align-items: center;
    //         justify-content: space-between;
    //         width: 100%;
    //         display: flex;
    //         position: relative;
    //         z-index: 2;
    //         min-height: 67px;
    //         .close-menu{
    //             transform: translateX(-11px);
    //             display: block;
    //             position: relative;
    //             &::after{
    //                 content: '';
    //                 position: absolute;
    //                 top: 50%;
    //                 left: 50%;
    //                 transform: translate(-50%,-50%);
    //                 width: 45px;
    //                 height: 45px;
    //             }
    //             svg{
    //                 transform: scale(1.5);
    //                 fill: $color-white;
    //             }
    //         }
    //     }
    //     @include rmin(1200){
    //         display: none;
    //     }
    //     @include rIn(768,1199){
    //         padding: 3px 37px;
    //     }
    //     @include r(767){
    //         padding: 3px 15px;
    //     }
    // }
    &__close{
        position: absolute;
        top: 14px;
        right: 10px;
        width: 40px;
        height: 40px;
        background-color: $color-white;
        z-index: 2;
        cursor: pointer;
        transition: all $transition-base;
        @include rmin(1200){
            display: none;
        }
        &:hover {
            color: dark($color-primary, 10%);
            background-color: $color-white;
            border-color: $color-white;
        }
        span {
            display: inline-block;
            @extend %overlay;
            width: 19px;
            height: 1px;
            background-color: $color-primary;
            margin: auto;
            &:first-child {
                transform: rotate(-45deg);
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
        }
    }
    &__body{
        .before-load-script &{
            visibility: hidden !important;
        }
        display: flex;
        @include r(1199){
            margin: auto;
            padding: 30px 0;
            width: 100%;
            flex-flow: column wrap;
            flex: 0 0 auto;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            position: relative;
            z-index: 2;
        }
        @include rmin(1200){
            flex: 1;
            justify-content: flex-end;
        }
        @include rmin(1200){
            .btn.btn_outline-header{
                margin-right: 25px;
            }
        }
        @include r(1199){
            .btn.btn_sm{
                min-width: 180px;
                max-width: 100%;
                &:last-child{
                    margin-top: 20px;
                }
            }
            .btn.btn_outline-header{
                margin-top: 3vh;
            }
            .btn_arrow {
                background-color: white;
                color: $color-primary;
                .arrow:after {
                    background-color: rgba(121,155,173,0.7);
                    z-index: -1;
                }
            }
        }
    }
}

.main-menu{
    @extend %reset-list;
    display: flex;
    align-items: center;
    .menu-item{
        padding: 0 17px;
    }
    @include r(1199){
        flex-flow: column wrap;
        li{
            margin: 0 0 2.5vh;
            &:last-child {
                margin-bottom: 0;
            }
        }
        a{
            text-align: center;
            font-size: 3vh;
            color: $color-white;
        }
    }
    a{
        text-decoration: none;
        font-weight: 600;
        transition: all $transition-base;
        color: $color-white;
        @include rmin(1200){
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                bottom: -5px;
                left: 0;
                background-color: transparent;
                transform: scaleX(0);
                transform-origin: 0 50%;
                transition: transform $transition-base;
            }
            &:hover:not(.is-current){
                &::after{
                    transform: scaleX(1);
                    background-color: currentColor;
                    opacity: .4;
                }
            }
            &.is-current{
                &::after{
                    transform: scaleX(1);
                    background-color: currentColor;
                }
            }
            .layout__header.is-fixed &,
            .dark-header .layout__header &{
                color: $color-dark;
                &:hover{
                    color: $color-primary;
                }
                &.is-current{
                    color: $color-primary;
                }
            }
        }
        @include r(1199){
            font-weight: 400;
            font-family: $main-font;
        }
        &:hover{
            color: $color-white;
        }
    }

}