.modal {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-gray;
    overflow-y: auto;
    z-index: 999999;
    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        // height: 100%;
        max-width: 100%;
        overflow-y: auto;
        // top: 50%;
        // left: 50%;
        // width: 1366px;
        // transform: translate(-50%, -50%);
        background-color: $color-white;
        // @media only screen and (max-width: 768px){
        //     max-width: 90%;
        // }
        // @include rmin(768){
        //     max-width: 720px;
        // }
        // @include rmin(992){
        //     max-width: 960px;
        // }
        // @include rmin(1200){
        //     max-width: 1200px;
        // }
        @media (min-width:1024px) and (min-height:800px) {
            display: flex;
            flex-direction: column;
        }
    }

    .close {
        position: relative;
        width: 50px;
        height: 50px;
        background: $color-primary;
        cursor: pointer;
        transition: $transition-base;
        @media only screen and (max-width: 768px){
            width: 40px;
            height: 40px;
        }

        &:hover {
            color: #fff;
            background-color: #5c8397;
            border-color: #5c8397;
        }
        span {
            position: absolute;
            display: inline-block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 19px;
            height: 1px;
            background: #fff;
            margin: auto;
            &:first-child {
                transform: rotate(-45deg);
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
        }
    }
    &__success {
        // display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-direction: column;
        &-img {
            display: block;
            margin-top: 50px;
        }
    }
    &__holder {
        display: none;
        background-color: inherit;
        flex-direction: column;
        @media only screen and (max-width: 768px){
            padding-bottom: 32px;
        }
        @media (min-width:1024px) and (min-height:800px) {
            margin: -135px auto 0;
            flex: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
    }
}

.contactModal {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 45px 50px;
        @media only screen and (max-width: 992px){
            padding: 15px;
        }
        @media (min-width:1024px) and (min-height:800px) {
            position: relative;
            z-index: 2;
        }
    }

    &__top {
        margin-bottom: 32px;
    }
    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 768px){
            flex-direction: column;
        }
    }

    &__footer {
        width: 100%;
        margin-top: 42px;
        padding: 30px;
        border-top: 1px solid $color-dark;
        @media (min-width:1024px) and (min-height:800px) {
            position: fixed;
            bottom: 0;
            left: 0;
        }
        @media only screen and (max-width: 768px){
            display: none;
        }
    }
    &__address {
        display: flex;
        align-items: center;
        justify-content: center;
        .textLocation {
            display: inline-block;
            margin-right: 10px;
            width: 16px;
            height: 18px;
        }
        p {
            margin: 0;
            font-size: 14px;
            line-height: 21px;
            color: $color-dark;
            opacity: 0.5;
        }
    }
    &__logo {
        display: inline-block;
        width: 229px;
        height: 24px;
        color: $color-primary;
        @media only screen and (max-width: 768px){
            width: 132px;
            height: 13.83px;
        }
    }
    &__content {
        width: 770px;
        margin: 0 auto;
        @media only screen and (max-width: 992px){
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &__title {
        margin-bottom: 24px;
        font-size: 60px;
        line-height: 68px;
        color: $color-dark;
        @media only screen and (max-width: 992px){
            font-size: 32px;
            line-height: 38px;
        }
    }
    &__subtitle {
        margin: 0;
        font-size: 15px;
        line-height: 26px;
        color: $color-dark;
        opacity: 0.8;
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        margin-bottom: 40px;
        @media only screen and (max-width: 992px){
            display: none;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        padding: 24px 30px;
        background: #FFFFFF;
        box-shadow: 0px 48px 64px rgba(0, 0, 0, 0.05);
        text-decoration: none;
        span {
            display: inline-block;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: $color-primary;

        }
        a{
            color: $color-primary;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
        &:hover{
            box-shadow: 0px 48px 64px rgba(0, 0, 0, 0.07);
        }
    }
    &__icon {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
    .btn_arrow {
        min-height: 58px;
        padding-right: 71px;
    }







}


.contactModal{
    // templ
    .contactModal__form,
    .wpcf7{
        display: none;
    }
    .contactModal__items{
        @include r(767){
            display: block;
            .contactModal__item:first-child{
                margin-bottom: 15px;
            }
        }
        @include rIn(768,1023){
            display: grid;
        }
    }
    @include rmin(1024){
        .modal__wrapper{
            display: flex;
            flex-flow: column wrap;
        }
        .modal__holder{
            flex: 1;
        }
        .contactModal__footer{
            margin-top: auto;
        }
    }
    // templ
}


.terms-block {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $color-dark;
    @media only screen and (max-width: 768px){
        margin-bottom: 24px;
    }
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked + span:after {
            content: '';
            display: block;
            position: absolute;
            top: 3px;
            left: 6px;
            width: 5px;
            height: 8px;
            border: solid $color-dark;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }
    label {
        position: relative;
        cursor: pointer;
        &::before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 1px solid $color-dark;
            border-radius: 4px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 7px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;
        }
    }
    a {
        display: inline-block;
        color: $color-primary;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}