.intro{
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    &-slider{
        width: 100%;
        .h1{
            @include r(450){
                font-size: 23px;
            }

        }
        &__item{
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            display: flex !important;
            flex-flow: column wrap;
            justify-content: center;
            position: relative;
            &::before{
                content: '';
                @extend %overlay;
                z-index: 0;
                background-color: rgba(0,0,0,.14);
                pointer-events: none;
            }
        }
        &__bg{
            @extend %overlay;
            @extend %bg-cover;
            z-index: -1;
        }
        &__titles{
            display: block;
            color: $color-white;
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            @include r(1023){
                text-align: center;
            }
            @include r(450){
                margin-top: -10vh;
            }
            @media (max-width: 1023px) and (orientation: landscape){
                margin-top: -10vh;
            }
            &__inner{
                @include rmin(768){
                    max-width: 600px;
                }
                @include r(1023){
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .subtitle{
                @include css-lock(14,32,450,1200);
                line-height: 40/32*1em;
                font-weight: 700;
                color: $color-white;
                text-decoration: none;
                position: relative;
                backface-visibility: hidden;
                display: inline-block;
                width: auto;
                &:hover{
                    text-decoration: underline;
                }
                &__arrow-right{
                    position: absolute;
                    width: 20px;
                    height: 10px;
                    left: calc(100% + 10px);
                    top: 50%;
                    transform: translateY(-50%);
                }
                @supports(mix-blend-mode: exclusion){
                    &::after{
                        content: '';
                        position: absolute;
                        width: calc(100% + 30px);
                        background-color: $color-white;
                        mix-blend-mode: exclusion;
                        left: 0;
                        bottom: -0.15em;
                        height: 2px;
                        transition: height .3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
                        will-change: height;
                    }
                    &:hover{
                        text-decoration: none;
                        &::after{
                            height: calc(100% + 0.3em);
                        }
                    }
                }

            }
        }
    }

    &__down-btn,
    .slick-dots,
    &__goto{
        // display: none !important;
        position: absolute;
        z-index: 1;
        @include r(1023){
            // bottom: 104px;
        }
    }
    &__down-btn{
        display: none !important;
        // display: flex;
        left: calc(50% - 17px);
        transition: transform $transition-base;

        &:hover{
            transform: translateY(10px);
        }
        @include rIn(1024,1199){
            left: 15px;
            bottom: 88px;
        }
        @include rmin(1200){
            bottom: 40px;
            left: 65px;
        }
        @include r(1023){
            bottom: 30px;
            left: 15px;
            transform: none;
        }
        svg{
            fill: $color-white;
            width: 34px;
            height: 52px;
            @include rmin(1024){
                width: 48px;
                height: 73px;

            }
        }
    }
    &__goto{
        font-size: 12px;
        font-weight: 500;
        color: $color-white;
        left: 15px;
        display: none !important;

        @include rIn(1024,1199){
            left: auto;
            right: 15px;
            bottom: 80px;
            transform: rotate(90deg);
            transform-origin: 100% 50%;
        }
        @include rmin(1200){
            left: auto;
            right: 65px;
            bottom: 16vh;
            transform: rotate(90deg);
            transform-origin: 100% 50%;
        }
        @include r(1023){
            bottom: 25px;
            right: 15px;
            left: auto;
        }

    }
    &__decor{
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        user-select: none;
        pointer-events: none;
        .decor1,
        .decor2{
            width: 1px;
            height: 100%;
            opacity: 0;
            position: absolute;
        }
        .decor1{
            transition: background-image 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        .decor2{
            background-color: rgba($color-white, .2);
            right: 30%; // templ
        }
        @include r(1199){
            display: none;
        }

    }
    .slick-dots{
        @extend %reset-list;
        display: flex;
        right: 15px;
        height: 15px;
        @include rmin(1200){
            height: 24px;
            bottom: 40px;
            right: 65px;
        }
        @include rIn(768,1199){
            bottom: 25px;
        }
        @include r(767){
            transform: rotate(-90deg);
            left: 100%;
            bottom: 0;
        }
        button{
            @extend %reset-btn;
            display: block;
            overflow: hidden;
            text-indent: -999px;
            width: 2px;
            height: 100%;
            background-color: $color-white;
            transform-origin: 50% 100%;
            transition: transform $transition-base;
        }
        li{
            position: relative;
            &:not(:first-child){
                margin-left: 20px;
            }
            &::after{
                content: "";
                position: absolute;
                width: calc(100% + 10px);
                height: 100%;
                left: -5px;
                bottom: 0;
            }
            &.slick-active{
                button{
                    transform: scaleY(2.5);
                }
            }

        }
    }
    &__interface{
        opacity: 0;
        position: absolute;
        height: 110/768*100%;
        background-color: $color-white;
        bottom: 0;
        right: 65px;
        display: flex;
        align-items: stretch;
        @include rIn(1024,1199){
            left: 65px !important;
            right: 65px;
            height: 50px;
            opacity: 1;
        }
        @include r(1023){
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            height: 50px;
            opacity: 1;
        }
        @include rmin(1921){
            height: 10%;
        }
        .intro-slider__ii{
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            // transition: all $transition-base;
            color: $color-gray;
            position: relative;
            @include r(768){
                flex: 1 1 50px;
                max-width: 50px;
                width: 50px;
            }
            @include r(1023){
                .text{
                    display: none !important;
                }
            }
            @include rmin(1921){
                padding-right: 20px;
            }
            &:hover:not(.is-active){
                cursor: pointer;
            }
            &:not(:last-child)::after{
                content: '';
                position: absolute;
                top: calc(50% - 20px);
                left: 100%;
                width: 1px;
                height: 40px;
                background-color: rgba($color-dark, .2);
            }
            .num{
                font-size: 24px;
                line-height: 33px;
            }
            .num,
            .text{
                color: inherit;
                position: relative;
            }
            .text{
                opacity: 0;
                @include rmin(1200){
                    display: none;
                }
                @include rIn(1024,1199){
                    opacity: 1;
                    .title{
                        opacity: 0;
                        text-align: center;
                        padding-right: 40px;
                        display: none;
                    }
                    p{
                        display: none;
                    }
                }
            }
            &.is-active{
                flex: 0 0 auto;
                background-color: $color-primary;
                color: $color-white;
                @include r(1023){
                    flex: 1 1 50px;
                    max-width: 50px;
                    transform: scale(1.1);
                    transform-origin: 0 100%;
                }
                @include rIn(1024,1199){
                    flex: 1 1 auto;
                }
                .num{
                    margin: 0 5%;
                }
                // .num{
                //     top: -30px;
                // }
                // .text{
                //     top: -18px;
                // }
                .text{
                    // opacity: 1;
                    display: block;
                    flex: 1;
                    .title{
                        margin-bottom: 3px;
                        display: block;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.04em;
                        text-transform: uppercase;
                        @include rIn(1024,1199){
                            opacity: 1;
                        }
                    }
                    p{
                        margin: 0;
                        font-size: 14px;
                        line-height: 22px;
                        @include rIn(1024,1199){
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &__ban-text{
        width: 100%;
        max-width: 770px;
        position: absolute;
        background-color: $color-primary;
        color: $color-white;
        z-index: 2;
        box-shadow: 0px 48px 64px rgba(0, 0, 0, 0.05);
        font-weight: 400;
        font-family: $main-font;
        @include rmin(1921){
            max-width: 1100px;
            font-size: 17px;
        }
        @include rmin(1024){
            padding: 10px 25px;
            bottom: 0;
            font-size: 17px;
        }
        @include rIn(1024,1199){
            left: 50%;
            transform: translateX(-50%);
        }
        @include rmin(1200){
            left: unset;
            transform: none;
            right: 155px;
        }
        @media (max-width:1023px) and (orientation: landscape){
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 12px;
            p{
                margin-top: 0.3em;
                margin-bottom: .6em;
            }
        }
        @include rIn(768,1023){
            padding: 16px;
            max-width: 80%;
            left: 10%;
            bottom: 0;
            // transform: translateY(50%);
        }
        @include rIn(450,767){
            font-size: 13px;
        }
        @include r(767){
            padding: 5px 16px;
            bottom: 0;
            left: 15px;
            max-width: calc(100% - 30px);
        }
        @include r(450){
            font-size: 12px;
        }
    }
    // & ~ .about__section{
    //     @include rIn(1024,1199){
    //         padding-top: 150px;
    //     }
    //     @include rIn(768,1023){
    //         padding-top: 110px;
    //     }
    //     @include r(767){
    //         padding-top: 185px;
    //     }
    // }
}
//inner pages
.inner-intro{
    padding-top: 75px;
    @include rIn(1023,1199){
        padding-top: 120px;
    }
    @include rmin(1200){
        @include css-lock(140,200,1200,1920, padding-top);
    }
    &__top{
        position: relative;
        padding-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include r(767){
            flex-wrap: wrap;
        }
        @include r(1023){
            padding-bottom: 20px;
        }
        &_wide{
            @include rIn(768,1199){
                flex-wrap: wrap;
                .inner-intro__title,
                .inner-intro__text{
                    flex: 1 1 100%;
                    max-width: 100%;
                }
            }
            @include rmin(1200){
                .inner-intro__text{
                    max-width: 65%;
                }
            }
        }
        &_align-stretch{
            align-items: stretch;
        }

    }
    &__title{
        @include r(1023){
            flex: 1 1 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    &__text{
        max-width: 500px;

    }
    .full-img{
        @include rmin(1200){
            margin-bottom: 40px;
        }
    }
}

.full-img{
    width: 100%;
    position: relative;
}


#iSlider:not(.slick-initialized) .intro-slider__item:not(:first-child){
    display: none;
}