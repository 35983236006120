// Typography
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0.3em;

    &:last-child {
        margin-bottom: 0;
    }
}

h1, .h1 {
    @include css-lock(32,48,768,1200);
    line-height: 56/48*1em;
    font-weight: 700;
}

h2, .h2 {
    @include css-lock(32,60,768,1200);
    line-height: 68/60*1em;
    font-weight: 500;
    &.h2_small{
        @include css-lock(32,48,768,1200);
        font-weight: 500;
    }
}

h3, .h3 {
    font-size: 40px;
    line-height: 52/40*1em;
}

h4, .h4 ,
h5, .h5 {
    @include css-lock(18,22,768,1200);
    @include css-lock(24,28,768,1200,line-height);
    font-weight: 600;
}

h6, .h6 {
    font-size: 18px;
    line-height: 22/18*1em;
    font-weight: 600;
    letter-spacing: 0.03em;
}
