// @import "../css/jquery.fullpage.min.css";
@import "./node_modules/slick-carousel/slick/slick.scss";
// @import "../../../../node_modules/slick-carousel/slick/slick.scss";
@import "utils/vars";
@import "utils/CSSvars.css";
@import "utils/fancybox.css";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/normalize";
// @import "../../../node_modules/bootstrap-4-grid/scss/grid.scss";

html, body {
    height: 100%;
}

html {
    font-size: $main-font-size;
    line-height: $main-line-height;
}

body {
    margin: 0;
    padding: 0;
    font-family: $main-font;
    font-size: $main-font-size;
    line-height: $main-line-height;
    font-weight: $main-font-weight;
    // color: $color-black;
    @extend %antialias;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    &.modal-open{
        overflow: hidden;
    }
    .no-touch &.modal-open{
        padding-left: 17px;
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}

img, svg {
    max-width: 100%;
}

svg {
    fill: currentColor;
    max-height: 100%;
}

button, a {
    cursor: pointer;
}

.layout {
    position: relative;
    overflow: hidden;
}

.container{
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    @include rmin(768){
        max-width: 720px;
    }
    @include rmin(992){
        max-width: 960px;
    }
    @include rmin(1200){
        max-width: 1200px;
    }
    &_wide{
        @include rmin(1200){
            @include css-lock(30,65,1200,1920, padding-left);
            @include css-lock(30,65,1200,1920, padding-right);
        }
        @include rmin(1){
            max-width: 100%;
        }

    }
}
.color-primary,
.higlight{
    color: $color-primary;
}

.form-group {
    position: relative;
    &__message {
        position: absolute;
        opacity: 0;
        left: 0;
        bottom: -20px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        color: #C81111;
        transition: $transition-base;
        @include rmin(768){
            bottom: -15px;
        }
    }
    &--error {
        .form-group__message {
            opacity: 1;
        }
    }
    input {
        font-size: 14px;
        line-height: 21px;
        width: 100%;
        height: 36px;
        border: none;
        color: $color-dark;
        opacity: 0.4;
        border-bottom: 1px solid $color-dark;
        background: transparent;
        outline: none;
    }
    label {
        display: block;
        margin-left: 2px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $color-dark;
    }

    textarea {
        display: block;
        font-size: 14px;
        line-height: 21px;
        color: $color-dark;
        opacity: 0.4;
        width: 100%;
        height: 96px;
        border: none;
        border-bottom: 1px solid $color-dark;
        background: transparent;
        outline: none;
    }
}

.form-groups {
    display: grid;
    grid-gap: 30px;
    margin-bottom: 38px;
    @media only screen and (max-width: 768px){
        grid-gap: 20px;
        margin-bottom: 20px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.col-1 {
    grid-template-columns: 1fr;
}

.col-2 {
    grid-template-columns: repeat(2, 1fr);
    @media only screen and (max-width: 768px){
        grid-template-columns: 1fr;
    }
}
.w-100{
    width: 100%;
}

@include r(767){
    h2 br {
        display: none;
    }
}

.js-slider-onMob {
    .slick-dots {
        margin: 20px auto 0;
        justify-content: center;
        li{
            height: 10px;
        }
        button {
            background-color: $color-primary;
            opacity: 0.2;
        }
        .slick-active {
            button {
                opacity: 1;
            }
        }
    }
    @include r(767){
        .slick-slide > div{
            margin: 12px;
        }

    }
}

// Components
@import "components";

// Utils
@import "utils/utils";

